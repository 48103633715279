$voop-dark: #181C24;
$voop-midnight: #13161D;
$voop-grey: #242932;
$voop-light-grey: #5d5d5d;
$voop-white: #F8F8FF;
$voop-purple: #4C46DC;
$voop-lilac: #818AD8;
$voop-green: #7DFFB4;
$voop-dark-green: #334b3c;

// todo separate all this into separate files or styled components



/* Fonts */

h1 {
  font-family: 'DM Sans', sans-serif !important;
  font-size: 64px;
  line-height: 64px;
}

h2 {
  font-family: 'DM Sans', sans-serif !important;
  font-size: 24px !important;
  line-height: 42px !important;
}

h3 {
  font-family: 'DM Sans', sans-serif !important;
  font-size: 18px !important;
  line-height: 42px !important;
}

p {
  font-family: 'DM Sans', sans-serif !important;
  font-size: 24px;
  line-height: 32px;
}

a {
  font-family: 'DM Sans', sans-serif !important;
  font-size: 24px;
  line-height: 32px;
}

button {
  font-family: 'DM Sans', sans-serif !important;
  font-size: 18px !important;
  line-height: 22px !important;
}


/* Navigation */
.navbar {
  z-index: 99;
  display: flex;
  position: fixed;
  width: 100%;
  padding: 10px 40px 10px 40px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid $voop-grey;
  background-color: #181C24;

  &__right-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}


// new styles

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  background-color: $voop-dark;
  color: $voop-white;
  font-size: calc(10px + 2vmin);
  box-sizing: border-box;
}

.home-dashboard {
  padding-top: 60px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 40px !important;
    font-weight: normal;
  }

  &__top-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 40px;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__top-row-left {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 0;
    }
  }

  &__top-row-right {
    display: flex;
    flex-direction: column;
  }


}

.voop-button {
  background-color: $voop-purple !important;
  height: 55px;
  color: $voop-white !important;
  border: none;
  border-radius: 25px !important;
  padding: 15px 30px !important;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-transform: none !important;
  font-weight: 700 !important;

  &:hover {
    background-color: $voop-purple;
  }
}


.dashboard {
  display: flex;
  flex-direction: column;

  & .row {
    box-sizing: border-box;
    // padding: 20px;
    display: flex;
    flex-direction: column;
    // margin-bottom: 20px;
    position: relative;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: $voop-light-grey;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }

    & .sub-row {
      display: flex;
      justify-content: space-between;

      flex-direction: column;

      @media (min-width: 768px) {
        flex-direction: row;
      }
    }

    & .sub-column {
      width: 75%;
      float: left;

      @media (max-width: 768px) {
        width: 100%;
      }

      &:last-child {
        width: 25%;
      }
    }
  }

  .btn {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &-blue {
      background-color: #663399;
      color: #FFFFFF;
    }
  }

  .grid-box {
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    & .box {
      background-color: #333366;
      box-sizing: border-box;
      padding: 10px;
      width: 24%;
      margin: 0.5%;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .row2 {
    flex-direction: row !important;

    & .column75 {
      width: 70%;
    }

    & .column25 {
      width: 30%;
    }

    & .column {
      box-sizing: border-box;
      //padding: 10px;
    }

    @media (max-width: 768px) {
      flex-direction: column !important;

      & .column75,
      & .column25 {
        width: 100%;
      }
    }
  }

  .row3 .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* keeps the last two rows in the same place */
  }

  .btn-white {
    background-color: #FFFFFF;
    color: #000033;
    /* dark bluish */
  }

  .row4 {
    .grid-box {
      display: flex;

      .column {
        display: flex;
        flex-direction: column;
        width: 32%;
        padding: 10px;
        box-sizing: border-box;


        .box {
          display: flex;
          flex-direction: column;
          width: 100%; // Full width inside the column
          margin-bottom: 10px; // Spacing between boxes in the first column

          &:last-child {
            margin-bottom: 0; // No margin for the last box
          }

          p {
            flex: 1; // Make paragraphs take up all available space
          }
        }
      }

      .column-single {
        display: flex;
        flex-direction: column;

        .box-full {
          display: flex;
          flex-direction: column;
          height: 100%;

          .box-inner {
            flex: 1;
            // ...other box styles...
          }
        }


        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .column-double {
        flex: 2;
        width: 33%;

        .box {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px; // Spacing between boxes in the first column

          &:last-child {
            margin-bottom: 0; // No margin for the last box
          }

          p {
            flex: 1; // Make paragraphs take up all available space
          }
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}


/* App */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logo-small {
  width: 40px !important;
  height: 40px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


/* Gallery Page */
.gallery-page {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: baseline;
  //font-size: calc(10px + 2vmin);

  //background-blend-mode: multiply;

  width: 100vw;
  height: 100vh;

  &__container {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    gap: 20px 20px;

    height: 100vh;
  }

  $margin-top-amount: 44px;

  &__box-1 {
    box-sizing: border-box;
    margin-top: $margin-top-amount;
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
  }

  &__box-2 {
    box-sizing: border-box;
    margin-top: $margin-top-amount;

    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
  }

  &__box-3 {
    box-sizing: border-box;
    margin-top: $margin-top-amount;

    grid-area: 1 / 3 / 2 / 4;
    display: flex;
    padding: 30px;
    align-items: center;
    justify-content: center;
  }

  &__bottom-row {
    box-sizing: border-box;
    grid-area: 2 / 1 / 3 / 4;
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
    //max-height: 10vh;
    align-items: center;
    justify-content: center;
  }

  &__action-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__background {
    top: 0;
    left: 0;

    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 100vh;

    background-size: 10000px 10000px !important;
    filter: blur(2px);
  }

  //&__top-row {
  //  display: flex;
  //  flex-direction: row;
  //}

  &__select-buttons {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__select-buttons-left {
    display: flex;
    flex-direction: row;
  }
}

.blender {
  top: 0;
  left: 0;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
}

/** Galleries Page **/
.galleries-page {

  //padding-top: 60px !important;
  &__container {
    //padding: 30px;
  }

  &__title-holder {
    padding: 1px 20px;
    background-color: white;
    //color: white;
  }

  &__section {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: row;
    padding: 60px;
    word-break: break-all;

    &-box-1 {
      flex: 1 1 30%;
      /*grow | shrink | basis */
    }

    &-box-2 {
      flex: 1 1 30%;
      /*grow | shrink | basis */

    }

    &-box-3 {
      flex: 1 1 30%;
      /*grow | shrink | basis */
    }
  }

  &__gallery-image {
    border-radius: 15px;
    width: 300px;
    height: 300px;
  }

  &__section-box-3 {
    column-count: 5;
    column-gap: 1em;
    width: 100%;
    //max-height: 100vh;
    overflow: auto;

    img {
      width: 100%;
      object-fit: cover;
      display: block;
      margin-bottom: 1em;
      break-inside: avoid;
    }
  }
}

/* General Styles */
.bold {
  font-weight: bold;
}

.tab,
.site-icon,
button {
  cursor: pointer;
}

/* show-manual-upload-modal */
.show-manual-upload-modal {
  display: flex;
  flex-direction: column;
}


/** Loading spinner **/
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #cef;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #cef transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.hidden {
  display: none;
}



/* Login Page */
.login-screen {
  //display: flex;
  //flex-direction: column;
  //align-items: center;
  //justify-content: center;
  //font-size: calc(10px + 2vmin);

  //background-blend-mode: multiply;

  overflow-x: hidden;

  padding-top: 100px;
  padding-bottom: 100px;

  background: rgb(47, 34, 70);
  background: radial-gradient(circle, rgba(47, 34, 70, 1) 0%, rgb(19, 16, 38) 100%);
  width: 100vw;
  min-height: 100vh;

  align-content: center;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
  position: relative;


  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  &__sign-up {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: white;
    padding: 30px;
    border-radius: 30px;
  }

  &__sign-up-label {
    color: $voop-grey;
    margin-top: 10px;
    font-size: 16px !important;
  }

  &__title {
    font-size: 30px !important;
  }

  &__benefits {
    margin-left: 30px;
    font-size: 26px !important;

    margin-bottom: 50px;
  }

  &__left-column {
    z-index: 1;
    padding: 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  &__right-column {
    z-index: 1;
    padding: 5vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

  &__grid {
    position: absolute;
    display: flex;
    align-self: center;
    justify-self: center;
    z-index: 0;
  }

  &__laptop {
    width: 100%;
  }

  &__sign-up-title {
    font-size: 30px !important;
    color: $voop-grey !important;
  }

  &__background {
    top: 0;
    left: 0;

    position: absolute;
    z-index: -2;
    width: 100vw;
    height: 100vh;

    background-size: 10000px 10000px !important;
    filter: blur(2px);
  }

  &__admin-text {
    color: white;
    font-size: 100px !important;
  }
}

.navbar-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.campaign-box-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //justify-content: space-between;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
}

.campaign-box {
  background-color: $voop-grey;
  width: 300px;
  padding: 45px 38px 45px 38px;
  border-radius: 25px;
  border: 1px solid $voop-dark-green;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    width: 450px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  &__link {
    text-decoration: none !important;
    color: $voop-white;
  }

  &__details {
    font-size: 16px !important;
    margin-bottom: 16px;
  }

  &__details-2 {
    font-size: 16px !important;

    &-icon {
      font-size: 16px !important;
    }
  }

  &__live {
    color: $voop-green;
  }

  &__title {
    color: $voop-white;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
    text-decoration: none !important;

    a {
      color: $voop-white;
      font-weight: normal;
      font-size: 34px;
      text-decoration: none;
    }
  }
}

.campaign-dashboard {
  padding-top: 60px;

  &__title {
    font-size: 36px !important;
  }

  &__details {
    font-size: 16px !important;
    margin-bottom: 16px;
  }

  // &__row-2 {
  //   border-bottom: 1px solid $voop-light-grey;
  //   min-height: 30vh;
  // }
}

.create-campaign-dashboard {
  display: flex;
  flex-direction: column;
  padding-top: 60px;

  @media (min-width: 768px) {
    flex-direction: row;
  }


  &__inner-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &__title {
    font-size: 40px !important;
    margin-bottom: 16px;
    font-weight: 500;
    line-height: 120%;
  }

  &__left {
    flex: 1 1 75%;
    margin-right: 30px;

    padding: 40px 20px;

    @media (min-width: 768px) {
      padding: 30px 100px;
    }
  }

  &__right {
    flex: 1 1 25%;
    border-left: 1px solid $voop-grey;
    padding: 30px 30px;
    position: relative;

    border-top: 1px solid $voop-grey;

    @media (min-width: 768px) {
      border-top: none;
    }
  }

  &__fixed-title {
    position: absolute;
    top: 33%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 185px;
    text-align: center;
  }

  &-mobile-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 45px;
  }

  &-mobile {
    width: 100%;

    @media (min-width: 768px) {
      padding: 1vw;
      width: 30vw;
    }
  }

  &__suggested-topics {
    margin-top: 52px;
    padding: 16px;
    background-color: $voop-midnight;
    border-radius: 15px;
    border: 1px solid $voop-light-grey;
  }

  &__suggested-topics-grid {
    display: flex;
    flex-direction: row;
  }

  &__suggested-topics-grid-column {
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex: 1 1 33%;

    p {
      color: $voop-lilac;
      font-size: 12px !important;
    }
  }

  &__input {
    width: 100%;
    color: $voop-white !important;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: $voop-grey !important;
    border: 1px solid $voop-light-grey !important;
  }
}

.date-calendar {
  background-color: $voop-grey !important;
  color: white;
  border-radius: 5px;
  max-width: 400px;
  border: 1px solid $voop-light-grey !important;

  input {
    font-family: 'DM Sans', sans-serif !important;
    color: $voop-white !important;
    width: 100%;
    padding: 5px 10px;
    width: 400px;
  }
}


.greyed-out {
  color: $voop-light-grey !important;
}

.terms {
  position: fixed;
  bottom: 0;
  right: 0;
  color: $voop-grey;
  display: none;
}

html,
body {
  width: 100vw;
  min-height: 100% !important;
  /* Using 100% because 100vh on mobiles will not include the navbar*/
}

#root {
  min-height: 100% !important;
  width: 100%;
}

.App {
  min-height: 100% !important;
  width: 100%;
}

.insight-page-override {
  justify-content: flex-start !important;
}

.insight-box {

  p {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    //text-overflow: ellipsis !important;
    overflow: hidden;
    //white-space: nowrap;
  }
}

.voop-feedback {

  &__voop-question {
    text-align: center;
    font-size: 40px !important;
    margin-bottom: 20px;
    line-height: 1.2em !important;
  }

  .terms-of-service {
    text-align: center;
    color: $voop-grey
  }

  .voop-white {
    color: $voop-white;
  }

  &__partner-logo {
    border: 1px solid $voop-white;
    border-radius: 2rem;
    padding: 10px 16px;
    margin-bottom: 16px;
  }

  &__feedback-message {
    text-align: center;
    line-height: 1em !important;
    color: $voop-white;
    font-size: 36px !important;
    //font-weight: bold;
    margin-bottom: 16px;
  }

  &__feedback-message-2 {
    text-align: center;
    line-height: 1.2em !important;
    color: $voop-white;
    font-size: 16px !important;
    //font-weight: bold;
    margin-bottom: 16px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    background-color: $voop-grey;
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 16px;
  }

  &__transcription {

    //text-align: center;

  }

  &__sentiment-label {
    align-self: flex-start;
    font-weight: normal !important;
  }

  &__sign-up-label {
    color: $voop-grey;
    margin-top: 10px;
    font-size: 16px !important;
  }

  &__email-container {
    width: 100%;
    margin-top: 10px;
  }

  &__email-input {
    width: 100%;
    background-color: $voop-grey !important;
    border: 1px solid $voop-light-grey;
    color: white !important;
  }
}

.metric-display {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.metric-segment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100px;
}

.bar {
  width: 100%;
  height: 30px;
}

//['angry', 'calm', 'disgust', 'fearful', 'happy', 'neutral', 'sad', 'surprised']



.metric-segment__mixed .bar,
.metric-segment__calm .bar {
  background-color: rgba(0, 0, 255, 0.5);
  border: 1px solid blue;
}

.metric-segment__label-mixed,
.metric-segment__label-calm {
  color: rgba(0, 0, 255, 0.5);
}

.metric-segment__negative .bar,
.metric-segment__angry .bar {
  background-color: rgba(255, 0, 0, 0.5);
  border: 1px solid red;
}

.metric-segment__label-negative,
.metric-segment__label-angry {
  color: rgba(255, 0, 0, 0.5);
}

.metric-segment__sad .bar {
  background-color: lightblue;
  border: 1px solid dodgerblue;
}

.metric-segment__label-sad {
  color: lightblue;
}

.metric-segment__disgust .bar {
  background-color: rgba(191, 0, 255, 0.5);
  border: 1px solid purple;
}

.metric-segment__label-disgust {
  color: rgba(191, 0, 255, 0.5);
}

.metric-segment__surprised .bar {
  background-color: orange;
  border: 1px solid orangered;
}

.metric-segment__label-surprised {
  color: orange;
}

.metric-segment__fearful .bar {
  background-color: violet;
  border: 1px solid darkviolet;
}

.metric-segment__label-fearful {
  color: violet;

}

.metric-segment__neutral .bar,
.metric-segment__neutral .bar {
  background-color: rgba(255, 255, 0, 0.5);
  border: 1px solid yellow;
}

.metric-segment__label-neutral,
.metric-segment__label-neutral {
  color: rgba(255, 255, 0, 0.5);
}

.metric-segment__positive .bar,
.metric-segment__happy .bar {
  background-color: rgba(0, 255, 0, 0.5);
  border: 1px solid green;
}

.metric-segment__label-positive,
.metric-segment__label-happy {
  color: rgba(0, 255, 0, 0.5);
}

.metric-label__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
}


// MUI

.account-page {

  .input {
    width: 100%;
    height: 40px;
    color: $voop-white !important;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: $voop-grey !important;
    border: 1px solid $voop-light-grey !important;
  }
}

.tab {
  color: $voop-white !important;
  &.Mui-selected {
    color: $voop-lilac !important;
  }
}
